// Variable overrides

// Overridden colors
$primary:       #3C98D9;
$secondary:     #A5A5A5;
$success:       #2FA59A;
// $info:          $light-blue !default;
$warning:       #C39E4F;
$danger:        #C95B60;
$light:         #ECEEF1;
// $dark:          $gray-800;

$main-horizontal-margin: 2rem;
$main-top-padding: 2rem;

$custom-header-height: 55px;
$custom-navbar-padding-top: 25px;
$custom-avatar-height: 37px;
$custom-avatar-width: 70px;
$custom-navbar-tab-width: 100px;
$custom-navbar-border-radius: 5px;
$custom-navbar-spacing: 2px;
$custom-navbar-bg: #39424A;
