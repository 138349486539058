// Here you can add other styles

.btn-warning {
  color: $white;
}

.redness {
  color: #fc0000;
}
.yellowness {
  color: #fbdb00;
}

input {
  &.bg-secondary {
    background-color: #dde3eb !important;
  }
  &::placeholder {
    font-style: italic;
  }
}

.dropdown-toggle {
  display: flex;
  text-align: left;
  align-items: center;
  .dropdown-toggle-text {
    flex-grow: 1;
  }
}

.app {
  background-color: $dark;
}

.main {
  margin-left: $main-horizontal-margin;
  margin-right: $main-horizontal-margin;
  background-color: $white;
}

.reset-text {
  font-weight: normal;
  text-transform: none;
}

// App Header
.app-header.custom {
  background-color: $dark;
  border: none;

  .brand-dropdown {
    margin-left: 2rem;
    margin-right: 2rem;

    .brand-selected {
      display: inline-block;
      min-width: 180px;
      text-align: left;
      font-size: 1rem;
      line-height: 1rem;
      margin-top: 0.2rem;
    }

    .brand-entry {
      padding: 10px;

      .brand-name {
        display: inline-block;
        width: 188px;
        font-size: 1rem;
      }

      .brand-icon {
        font-size: 0.5rem;
      }
    }
  }

  .navbar-nav {
    line-height: $custom-header-height - $custom-navbar-padding-top;
    padding-top: $custom-navbar-padding-top;

    &.nav-avatar {
      line-height: $custom-avatar-height;
      padding-top: $custom-header-height - $custom-avatar-height;
    }

    .avatar-dropdown {
      text-align: left;
      width: $custom-avatar-width;
      min-width: $custom-avatar-width;
      border-top-right-radius: 0;
      border-top-left-radius: 5px;
      background-color: rgba($white, 0.25);
    }

    .img-avatar {
      height: 30px;
    }

    li {
      background-color: rgba($white, 0.65);
      border-top-left-radius: $custom-navbar-border-radius;
      border-top-right-radius: $custom-navbar-border-radius;
      margin-left: $custom-navbar-spacing;
      min-width: $custom-navbar-tab-width;
      text-transform: uppercase;
      font-weight: bold;
      a {
        color: $dark;
      }
    }

    li.active {
      background-color: $white;
    }
  }
}

// Card
.data-card {
  padding: 1.5rem;

  .card-header {
    text-transform: uppercase;
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    font-weight: bold;
    font-size: 120%;
    line-height: 2rem;
  }

  .btn {
    padding-top: 0.25rem;
    padding-right: 0.5rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;

    &.dropdown-toggle {
      width: 7em;
    }
  }

  .card-body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  .data-details {
    line-height: 2rem;

    .data-label {
      color: $dark;
    }

    .data-value {
      font-weight: bold;
    }
  }

  .table-responsive {
    min-height: 180px;
  }

  table {
    &.tray {
      margin-bottom: 0;
      td {
        border: solid 2px #c8ced3;
        padding: 0;
        div {
          &.bottle-top {
            width: 6px;
            height: 4px;
            border-radius: 2px 2px 0 0;
            margin: 2px auto 0 auto;
          }
          &.bottle-bottom {
            margin: 0 2px 2px 2px;
            width: 10px;
            height: 11px;
            border-radius: 2px;
          }
          &.filled {
            background-color: #afc2cc;
          }
        }
      }
    }
  }

  thead th {
    text-transform: uppercase;
    &.sortable {
      cursor: pointer;
    }
  }
}

.tr-odd {
  background-color: rgba(0, 0, 0, 0.05);
}

.search-input {
  max-width: 300px
}

.info-popover {
    background-color: #3c98d9;
    font-size: 0.825rem;
  .arrow::after {
    border-bottom-color: #3c98d9;
  }
  .popover-body {
    color: white;
  }
}

#order-process {
  .row {
    align-items: center;
    &.active {
      border-left: solid 4px #dd9697;
    }
  }
  .info {
    font-style: italic;
    font-weight: bold;
    font-family: serif;
    font-size: 0.825rem;
    padding: 0.05rem;
  }
  label {
    color: #8c9da4;
    padding-top: 0.5em;
    &.name {
      color: black;
      margin-right: 0.25em;
    }
    &.value {
      color: black;
      font-weight: bold;
    }
    &.step {
      width: 145px;
      &.active {
        font-weight: bold;
      }
    }
  }
}

#productDropdown {
  .dropdown-toggle {
    min-width: 340px;
  }
}

@mixin order-status-dropdown($statuses...) {
  @each $i in $statuses {
    .status-#{nth($i, 1)} {
      .btn {
        background-color: nth($i, 2);
        border-color: nth($i, 2);
        color: nth($i, 3);
      }
      &.badge {
        background-color: nth($i, 2);
        color: nth($i, 3);
      }
    }
    .badge.#{nth($i, 1)} {
      background-color: nth($i, 2);
      color: nth($i, 3);
    }
  }
}

.skin-tone-modal {
  &.modal-lg {
    max-width: 678px;
  }
}

@include order-status-dropdown(
  "pending" orange $dark,
  "queued" gold $dark,
  "printed" blue $light,
  "printed1" grey $light,
  "processed" rgb(115, 194, 115) $light,
  "packaged" cyan $dark,
  "shipped" rgb(180, 78, 78) $dark,
  "delivered" rgb(68, 128, 68) $dark,
  "cancelled" $dark $light,
  "hold" purple $light
);
